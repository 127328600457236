<template>

  <div>

    <h3  v-if="title"        v-text="title"        class="font-bold text-4xl text-telkom-blue mb-8"></h3>
    <div v-if="title_html"   v-html="title_html"   class="markdown mb-8"></div>
    <div v-if="body_wysiwyg" v-html="body_wysiwyg" class="markdown mb-8"></div>
    <div v-if="body_html"    v-html="body_html"    class="markdown mb-8"></div>

  </div>

</template>

<script>
export default {

  name: 'TitleContent',

  props: {

    data: { type: Object }

  },

  computed: {

    title () {
      return this.data.title || false;
    },

    // Only return "title_html" if "title" is empty
    title_html () {
      let md = this.data.title_markdown || {};
      return this.title ? false : (md.html || false);
    },

    // Prefer "markdown_html" before "body_markdown.html"
    body_html () {
      let md = this.data.body_markdown || {};
      return this.markdown_html || md.html || false;
    },

    markdown_html () {
      let md = this.data.markdown || {};
      return md.html || false;
    },

    body_wysiwyg () {
      return this.data.wysiwyg || false;
    }

  }

}
</script>

<template>
  <div class="block text-center">

    <div class="pb-16">

      <h3 class="font-bold text-4xl xtitle text-telkom-blue px-6 py-8 mb-4">Thank you</h3>

      <p class="px-6 pb-6">Please select the button below to close this screen and return to the home page.</p>

    </div>

    <div class="bg-grey-lightest p-6">
      <button class="btn-telkom-blue-dark icon-caret-right" @click="$router.push({ name: 'home' })">
        <span class="pr-4">Return to Home page</span>
      </button>
    </div>

  </div>
</template>

<script>
export default {

  name: 'VaSummary'

};
</script>

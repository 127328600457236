<template>

  <div class="text-left">

    <!-- <h3 v-if="data.title" class="font-bold text-4xl text-telkom-blue px-6 py-8 mb-4 inline-block">{{ data.title }}</h3> -->
    <title-content :data="data"></title-content>

    <div v-if="continueLink" class="bg-grey-lightest p-6">
      <a class="btn-telkom-blue-dark icon-caret-right cursor-pointer hover:no-underline"
         :href="continueLink.url">
        <span class="pr-4">Continue</span>
      </a>
    </div>

    <template v-else>
      <div class="bg-grey-lightest p-6">
        <button class="btn-telkom-blue-dark icon-caret-right" @click.once="applyAction(data.action)">
          <span class="inline-block leading-none pr-4 p-0">
            {{ data.text || 'Continue' }}
          </span>
        </button>
      </div>
    </template>

  </div>
</template>

<script>

import TitleContent from '@/components/TitleContent.vue'

export default {

  name: 'VaRecord',

  components: {
    TitleContent
  },

  props: {
    data: Object
  },

  computed: {
    continueLink () {
      let documentcategory = 'SELFHELPRECORDLINK';
      return this.data.docs ? (this.data.docs.filter(doc => doc.documentcategory === documentcategory)[0] || false) : false;
      // if (this.data.docs) {
      //   return this.data.docs.filter(doc => doc.documentcategory === documentcategory)[0] || false;
      // } else {
      //   return false;
      // }
    }
  },

  methods: {
    applyAction (params) {
      this.$emit('applyAction', params);
    }
  }

};
</script>

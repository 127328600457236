<template>

    <div>

        <Breadcrumb :history="data.history || []" :title="data.title || 'You are here'" :crumbs-visible="breadcrumb.crumbsVisible" :show-current="breadcrumb.showCurrent" :more-text="breadcrumb.moreText"></Breadcrumb>

        <template v-if="data.journey">

            <div v-for="(card, key) in data.journey" :key="key">

                <div :id="`journey-card-${key}`" class="min-h-screen pb-16" v-if="card.data && card.data.state">

                    <component :is="getComponent(card.data.state || '')" :data="card.data" @applyAction="applyAction" />

                </div>

                <div class="flex items-center pb-16">
                    <div class="bg-telkom-blue-lightest w-full h-px"></div>
                    <button v-scroll-to="`#journey-card-${key}`" class="text-telkom-blue-lighter px-6 outline-none focus:outline-none flex flex-col items-center">
                        <i class="fa fa-chevron-up"></i>
                        <timeago :datetime="card.datetime" :auto-update="60" class="font-sans font-thin whitespace-no-wrap"></timeago>
                    </button>
                    <div class="bg-telkom-blue-lightest w-full h-px"></div>
                </div>

            </div>

        </template>

        <div v-if="data.state" :class="{ 'min-h-screen-with-footer': data.journey.length > 0, 'min-h-screen-with-header-footer':  data.journey.length === 0 }" ref="current-state">

            <component :is="getComponent(data.state || '')" :data="data" @applyAction="applyAction" />

        </div>

    </div>

</template>

<style lang="scss">
.min-h-screen-with-footer {
    min-height: calc(100vh - 115px);
}
.min-h-screen-with-header-footer {
    min-height: calc(100vh - 115px - 137px);
}
</style>

<script>

import localStore from '../localStore'

import Breadcrumb from '@/components/Breadcrumb.vue'

import VaStep     from '@/components/VaStep.vue'
import VaDecision from '@/components/VaDecision.vue'
import VaRecord   from '@/components/VaRecord.vue'
import VaRecords  from '@/components/VaRecords.vue'
import VaSummary  from '@/components/VaSummary.vue'
import Va404      from '@/components/Va404.vue'

import { mapState, mapActions } from 'vuex'

export default {

    name: 'VaJourney',

    components: {
        Breadcrumb,
        VaStep,
        VaDecision,
        VaRecord,
        VaRecords,
        VaSummary,
        Va404
    },

    data () {

        return {

            breadcrumb: {
                crumbsVisible: 0,
                showCurrent: false,
                moreText: '...'
            }

        }
    },

    computed: {

        ...mapState(['data'])

    },

    beforeRouteEnter (to, from, next) {

        let stateid = localStore.getStateid(to.params.guid || '');

        if ( !stateid ) { next({ path: '/notfound', replace: true }); return; }

        next(_this => { _this.loadState(stateid); });
    },

    beforeRouteUpdate (to, from, next) {

        let stateid = localStore.getStateid(to.params.guid || '');

        if ( !stateid ) { next({ path: '/notfound', replace: true }); return; }

        this.loadState(stateid);

        next();
    },

    mounted () {

        this.scrollToCurrentState();
    },

    updated () {

        this.scrollToCurrentState();
    },

    watch: {

        data(data) {

            data['stateid'] = parseInt(this.stateid, 10) || 0;

            this.setData(this.stateid, data);
        },

        '$screen.width': {

            immediate: true,

            handler() {

                const screenWidth = this.$screen.width;

                this.breadcrumb.showCurrent = false;
                this.breadcrumb.crumbsVisible = 0;
                this.breadcrumb.moreText = 'Back to ...';

                if (screenWidth >= 400) {
                    this.breadcrumb.moreText = '';
                    this.breadcrumb.showCurrent = true;
                }

                if (screenWidth >= 450) {
                    this.breadcrumb.crumbsVisible = 1;
                }
            }
        }

    },

    methods: {

        ...mapActions(['loadState', 'applyAction']),

        scrollToCurrentState () {

            // this.$nextTick(() => {

                let element = this.$refs['current-state'] || false;

                if (element) { this.$scrollTo(element, 500); }

            // });
        },

        getComponent (state) {

            let map = {
                'step'       : 'VaStep',
                'decision'   : 'VaDecision',
                'record'     : 'VaRecord',
                'records'    : 'VaRecords',
                'summary'    : 'VaSummary'
            };

            return map[state] || 'Va404';
        },

        setData (stateid, data) {

            let title = data.title ? `| ${data.title}` : '';

            document.title = `Telkom ${title}`;

            // this.loader()
            //     .then( () => { this.component = () => this.loader(); })
            //     .catch(() => { this.component = () => import('@/components/Va404.vue'); });
        }

    }

}
</script>

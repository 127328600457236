<template>

  <div>

    <title-content :data="data"></title-content>

    <div v-if="questionImages && questionImages.length">
      <div class="px-6 pb-6">
        <a v-for="doc in questionImages" :key="doc.documentid" :href="doc.url" target="_blank" class="block px-6 pb-6">
            <img v-if="doc.url" :src="doc.url" alt="[image]" style="max-width:100%;max-height:50%;" class="block" />
        </a>
      </div>
    </div>

    <div>

      <div v-if="externalLinks && externalLinks.length" class="pb-8">
        <a tabindex="0"
          v-for="doc in externalLinks"
          :key="doc.documentid"
          :href="doc.url"
          target="_blank"
          class="w-2/3 sm:w-5/6 xs:w-2/3 md:w-1/2 block mx-6 my-4 p-4 bg-telkom-blue hover:bg-telkom-blue-light text-lg text-white hover:text-white font-bold hover:no-underline focus:no-underline focus:text-white"
        >
          <div class="flex items-center px-2">
            <span class="flex-auto text-left">{{ doc.documentname }}</span>
            <i class="fa fa-external-link font-bold mt-2 ml-12" aria-hidden="true"></i>
          </div>
        </a>
      </div>

      <div v-if="hasImages && data.answers" class="flex flex-wrap">

        <VaAnswerButton
          v-for="choice in data.answers" :key="choice.action.factornameid"
          :answer="choice"
          @applyAction="applyAction"
          :showIcon="true"
          :class="'mx-6 my-2'"
          :btnStyle="'min-width:150px;max-width:150px;'"
          :imgWrapClass="'mx-auto'"
          :textClass="'block pt-4'"
          :btnClass="'bg-gray-100 p-4 inline-block'"
          :isQuestion="true"
        ></VaAnswerButton>

      </div>

      <div v-else-if="!hasImages">

        <!-- finder_question_choose -->
        <div v-if="hasSubs && data.answers">

          <VaAnswerButton
            v-for="choice in data.answers" :key="choice.actions.finder_question.factornameid"
            :answer="choice"
            @applyAction="applyAction(choice.actions.finder_question)"
            :showIcon="false"
            :class="'block mx-6 my-2 pb-4'"
            :btnClass="'font-bold text-xl mb-2 inline-block p-4 hover:transition-slow ' + (choice.chosen ? ' bg-telkom-blue text-white hover:text-white' : 'hover:bg-gray-400')"
            :isQuestion="true"
          >

            <div v-if="choice.answers">

              <VaAnswerButton
                v-for="answer in choice.answers" :key="answer.text"
                :answer="answer"
                @applyAction="applyAction"
                :showIcon="false"
                :class="'mx-2 my-2 inline-block'"
                :textClass="'font-bold text-lg'"
                :btnClass="'btn inline-block p-4 hover:transition-slow ' + (answer.chosen ? ' bg-telkom-blue text-white hover:text-white' : 'bg-gray-300 hover:bg-gray-400')"
                :isQuestion="false"
              ></VaAnswerButton>

            </div>

          </VaAnswerButton>

        </div>

        <div v-else-if="!hasSubs">

          <template v-if="data.answers">

            <VaAnswerButton v-for="choice in data.answers" :key="choice.action.factornameid" :answer="choice" @applyAction="applyAction"
              :showIcon="false"
              :class="'xmx-2 xmy-2 mr-4 mb-4 inline-block'"
              :textClass="'font-bold text-lg'"
              :btnClass="'btn inline-block p-4 hover:transition-slow ' + (choice.chosen ? ' bg-telkom-blue text-white hover:text-white' : 'bg-gray-300 hover:bg-gray-400')"
              :isQuestion="false"
            ></VaAnswerButton>

          </template>

          <template v-if="supportVas">

            <VaAnswerButton v-for="va in supportVas" :key="va.action.vaid" :answer="va" @applyAction="applyAction"
              :showIcon="false"
              :class="'mx-2 my-2 inline-block'"
              :textClass="'font-bold text-lg'"
              :btnClass="'btn bg-gray-300 hover:transition-slow inline-block p-4 hover:bg-gray-300'"
              :isQuestion="false"
            ></VaAnswerButton>

          </template>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import TitleContent from '@/components/TitleContent.vue'
import VaAnswerButton from '@/components/VaAnswerButton.vue'

export default {

  name: 'VaDecision',

  components: {
    TitleContent,
    VaAnswerButton
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    questionImages() {
      let documentcategory = 'SELFHELPFACTORNAMEIMAGE';
      if (this.data.docs) {
        return this.data.docs.filter(doc => doc.documentcategory === documentcategory) || [];
      } else {
        return [];
      }
    },
    externalLinks () {
      let documentcategory = 'SELFHELPEXTERNALLINK';
      if (this.data.docs) {
        return this.data.docs.filter(doc => doc.documentcategory === documentcategory) || [];
      } else {
        return [];
      }
    },
    hasImages () {
      if (this.data.answers) {
        return this.data.answers.filter(answer => this.hasImg(answer)).length ? true : false;
      }
      return false;
    },
    substate () {
      return this.data.substate || 'decision';
    },
    hasSubs () {
      return this.substate === 'finder_question_choose' ? true : false;
    },
    wysiwyg () {
      return this.data.description ? this.data.description.html || '' : '';
    },
    markdown () {
      return this.data.description ? this.data.description.markdown || '' : '';
    },
    supportVas () {
      let support = this.data.support || {};
      return support.vas || [];
    }
  },

  methods: {
    hasImg (choice) {
      return choice && choice.img && choice.img.src ? true : false;
    },
    // applyAction (params) {
    //   this.$emit('applyAction', params);
    // }
    applyAction (action) {
      this.$emit('applyAction', { action, data: this.data });
    }
  }

};
</script>

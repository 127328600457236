<template>
  <div>

    <div class="flex">

      <div class="flex-auto">

        <a tabindex="0" @click="handler(answer)" @keyup.enter="handler(answer)" :class="classes" :style="btnStyle">

          <VaIcon v-if="showIcon" :imgClass="imgClass" :class="imgWrapClass" style="line-height:80px" :answer="answer"/>

          <div v-if="titleMarkdown" v-html="titleMarkdown" :class="'markdown inline-block ' + (isQuestion ? 'text-base font-normal w-auto md:w-3/4 xl:2/3 ' : '') + (isAnswer ? 'text-sm ' : '')"></div>

          <span v-else :class="textClass">{{ buttonText }}</span>

        </a>
        <!-- chosen = {{ answer.chosen }} -->
        <!-- <div v-if="answer.action.actionid">{{ answer.action.actionid }}</div> -->
        <!-- <pre v-html="answer" class="text-xs"></pre> -->

      </div>

      <div v-if="bodyMarkdown">
        <!-- <popper trigger="click" :options="{placement: 'top'}"> -->
        <popper trigger="click" :options="{placement: 'auto'}">
          <div class="popper">
            <div v-html="bodyMarkdown" class="markdown p-4"></div>
          </div>
          <div slot="reference">
            <button class="bg-telkom-blue text-xs p-4 text-gray-100 font-bold leading-loose"><i class="fa fa-info"></i></button>
          </div>
        </popper>
      </div>

    </div>

    <slot></slot>

  </div>
</template>

<script>

import Popper from 'vue-popperjs';
// import 'vue-popperjs/dist/vue-popper.css';

import VaIcon from '@/components/VaIcon.vue';

export default {

  name: 'VaAnswerButton',

  components: {
    VaIcon,
    Popper
  },

  props: {
          answer : { type: Object, required: true },
            text : { type: String, default: '' },
    imgWrapClass : { type: String, default: '' },
        imgClass : { type: String, default: '' },
       textClass : { type: String, default: '' },
       baseClass : { type: String, default: 'cursor-pointer hover:no-underline focus:no-underline' },
        btnClass : { type: String, default: '' },
        btnStyle : { type: String, default: '' },
        showIcon : { type: Boolean, default: true },
      isQuestion : { type: Boolean, default: false }
  },

  computed: {
    isAnswer () {
      return !this.isQuestion;
    },
    classes () {
      return [this.baseClass, this.btnClass].join(" ");
    },
    url () {
      return this.getAnswerLink(this.answer) || this.getOneMatchingRecordLink(this.answer) || false;
    },
    urlGoToRoutePath () {
      return this.goToRoutePath(this.answer) || false;
    },
    buttonText () {
      return this.text || this.answer.text || '';
    },
    titleMarkdown () {
      let answer = this.answer || {};
      let markdown = answer.title_markdown || {};
      return markdown.html || '';
    },
    bodyMarkdown () {
      let answer = this.answer || {};
      let markdown = answer.body_markdown || {};
      return markdown.html || '';
    }
  },

  methods: {
    handler () {
      if (this.urlGoToRoutePath) {
        this.$router.push({ path: this.urlGoToRoutePath });
      } else if (this.url) {
        window.location = this.url;
      } else {
        this.$emit('applyAction', this.answer.action || {});
      }
    },
    getOneMatchingRecordLink (answer) {
      let record = answer.one_matching_record || {};
      let docs = record.docs || [];
      let doc = docs.filter(doc => doc.documentcategory === 'SELFHELPRECORDLINK')[0] || {};
      return doc.url || '';
    },
    goToRoutePath (answer) {
      let record = answer.one_matching_record || {};
      let docs = record.docs || [];
      let doc = docs.filter(doc => doc.documentcategory === 'SELFHELPGOTOROUTEPATH')[0] || {};
      return doc.url || '';
    },
    getAnswerLink (answer) {
      let documentcategory = 'SELFHELP';
      let docs = answer.docs || answer.one_matching_record_docs || [];
      let doc = docs.filter(doc => doc.documentcategory === documentcategory)[0] || {};
      return doc.url || '';
    }
  }

}
</script>

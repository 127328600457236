<template>
  <div v-if="data.matching && data.matching.records">
    <div v-for="record in data.matching.records" :key="record.recordid" class="mb-8">
      <h3 class="title text-telkom-blue">{{ record.description }}</h3>
      <div class="btn-group" role="group" aria-label="Actions">
        <template v-for="(button, key) in record.actions">
          <button :key="key" @click="$emit('applyAction', button || {})" type="button" class="btn-telkom-blue">{{ button.text || 'Choose' }}</button>
        </template>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'VaRecords',

  props: {
    data: Object
  }

}
</script>

<template>

  <div>

    <title-content :data="data"></title-content>

    <va-form :data="data"></va-form>

    <div class="flex flex-wrap bg-grey-lightest p-2">

      <template v-if="supportVas">
        <VaAnswerButton
          v-for="va in supportVas" :key="va.action.vaid"
          class="m-2"
          btnClass="btn-telkom-blue-dark"
          textClass=""
          :showIcon="false"
          :answer="va"
          :text="va.text || ''"
          @applyAction="applyAction"
        />
      </template>

      <VaAnswerButton
        class="m-2"
        btnClass="btn-telkom-blue-dark icon-caret-right"
        textClass="pr-4"
        :showIcon="false"
        :answer="data"
        :text="data.text || 'Continue'"
        @applyAction="applyAction"
      />

    </div>

  </div>

</template>

<script>

import TitleContent from '@/components/TitleContent.vue'
import VaForm from '@/components/form/VaForm.vue'
import VaAnswerButton from '@/components/VaAnswerButton.vue'

// import { mapActions } from 'vuex'
// import { mapState } from 'vuex'

// import Vue from 'vue'

export default {

  name: 'VaStep',

  components: {
    TitleContent,
    VaForm,
    VaAnswerButton
  },

  props: {

    data: { type: Object }

  },

  computed: {

    wysiwyg () {
      return this.data.description ? this.data.description.html || '' : '';
    },

    markdown () {

      // return this.data.description ? this.data.description.markdown || '' : '';
      let markdown = this.data.markdown || {};
      return {
        'html': markdown.html || '',
        'markdown': markdown.markdown || ''
      };

    },

    supportVas () {
      let support = this.data.support || {};
      return support.vas || [];
    }

  },

  methods: {

    // ...mapActions(['applyAction'])
    applyAction (action) {

      let form = this.data.form || {};

      let formfields = form.fields || [];

      let recaptcha = form.recaptcha || { enabled: false };

      action.formfields = formfields;

      recaptcha.error = false;

      if (recaptcha.enabled) {

        recaptcha.token = false;
        recaptcha.error = false;
        recaptcha.busy = true;

        this.$recaptcha('form').then( token => {

          recaptcha.busy = false;
          recaptcha.token = token;
          // recaptcha.sitekey = this.$store.state.recaptchaSitekey || false;
          // action['g-recaptcha-token'] = token;
          action['recaptcha'] = {
            sitekey: this.$store.state.recaptchaSitekey || false,
            token: token
          };

          // this.$store.dispatch('applyAction', action);
          this.$store.dispatch('applyAction', { action, data: this.data });

        });

      } else {

        // this.$store.dispatch('applyAction', action);
        this.$store.dispatch('applyAction', { action, data: this.data });
      }

    }

  }

}
</script>
